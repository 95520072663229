import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  PieChart, Pie, Cell, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Text,
} from 'recharts';
import {
  Row,
  Col,
  Card,
  Statistic,
  Badge,
  Skeleton,
  Select,
  Space,
  Button,
  DatePicker,
  Radio,
  Drawer,
  Table,
  Typography,
  Tabs,
  Pagination,
  Divider,
  Form,
} from 'antd';
import { blue, green, red } from '@ant-design/colors';
import { FilterTwoTone } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import MapContainer from '../SellerMap';

const { Title } = Typography;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function SellerStats() {
  const [mainTab, setMainTab] = useState('1');
  const [displayTab, setDisplayTab] = useState('1');
  const [displayMetric, setDisplayMetric] = useState('qty_orders');
  const [segmentedColumn, setSegmentedColumn] = useState(null);
  const [segmentedData, setSegmentedData] = useState(null);
  const [segmentedLoading, setSegmentedLoading] = useState(false);

  const [aggregatedData, setAggregatedData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [buyerGraphData, setBuyerGraphData] = useState([]);
  const [generalStats, setGeneralStats] = useState([]);

  const [comparison, setComparison] = useState(null);

  const [filterValues, setFilterValues] = useState({
    customerOrder: 'hasOrder', customerStatus: 'all', abcCurve: 'all', registerDate: 'all',
  });

  const [graphType, setGraphType] = useState('line');
  const [analysisPeriod, setAnalysisPeriod] = useState('last7');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buyerLoading, setBuyerLoading] = useState(true);
  const [customRange, setCustomRange] = useState(null);

  const [width, height] = useWindowSize();
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [form] = Form.useForm();

  const { Text, Link } = Typography;
  const { RangePicker } = DatePicker;
  const { TabPane } = Tabs;

  useEffect(() => form.setFieldValue(filterValues), [filterValues]);

  const labelToTitle = {
    RTB: "Prontos Para Comprar",
    RISK: "Em Risco",
    SERVED: "Atendidos",
    LOST: "Perdidos",
    above1Year: "Acima de 1 Ano",
    last12Months: "No Último Ano",
    last30Days: "Últimos 30 Dias",
    last7Days: "Últimos 7 Dias",
    today: "Hoje",
    pf: "Pessoa Física",
    pj: "Pessoa Juridica",
    seller: "Vendedor (Admin)",
    selleruser: "Vendedor",
    buyer: "Cliente"
  }

  const convertToChartData = (data) => {
    const chartData = [];
    for (const key in data) {
      const values = [];
      for (const innerKey in data[key]) {
        values.push({ label: innerKey, title: labelToTitle[innerKey] ? labelToTitle[innerKey] : innerKey, qty_buyers: data[key][innerKey] });
      }
      chartData[key] = values;
    }
    return chartData;
  };

  useEffect(() => {
    getBuyerData();
  }, []);

  useEffect(() => {
    segmentedColumn && getBuyerData(segmentedColumn, displayTab);
  }, [segmentedColumn]);

  function getBuyerData(segmentedColumn = null, displayTab = null) {
    !segmentedColumn && setBuyerLoading(true);
    segmentedColumn && setSegmentedLoading(true);

    const filteredItem = itemsCustomerTab.filter((item) => item.key === displayTab);
    const column = filteredItem.length > 0 ? filteredItem[0].column : null;

    axiosClient
      .post('/buyer/stats', {
        filter_values: filterValues,
        column,
        segmented_data: segmentedColumn,
      }, { withCredentials: true })
      .then((response) => {
        if (!segmentedColumn) {
          const chartData = convertToChartData(response.data.results);
          setBuyerGraphData(chartData);
          setBuyerLoading(false);
        } else {
          setSegmentedData(response.data.records);
          setSegmentedLoading(false);
        }
      });
  }

  function getChange(current, previous) {
    if (previous !== 0) {
      const change = ((current - previous) / previous * 100.0);
      return parseFloat(change.toFixed(2));
    } if (current !== 0) {
      return 'inf';
    }
    return 0.0;
  }

  function mergeDicts(actual, compare) {
    const merged = {};
    for (const key in actual) {
      if (key in compare) {
        merged[key] = actual[key].map((actualItem) => {
          const compareItem = compare[key].find((compareItem) => compareItem.label === actualItem.label);
          if (compareItem) {
            const mergedItem = {};
            for (const prop in actualItem) {
              mergedItem[prop] = actualItem[prop];
              mergedItem[`compare_${prop}`] = compareItem[prop];
            }
            mergedItem.label = actualItem.label;
            return mergedItem;
          }
          return actualItem;
        });
      }
    }
    return merged;
  }

  useEffect(() => {
    if (analysisPeriod != 'editCustom') {
      setLoading(true);
      axiosClient
        .post('/cart/stats', {
          analysisPeriod, customRange,
        }, { withCredentials: true })
        .then((response) => {
          const cartStatsDataActual = response.data.actual_data
          const cartStatsDataCompare = response.data.compare_data
          for (const key in cartStatsDataActual) {
            cartStatsDataActual[key].forEach(item => {
              item.title = labelToTitle[item.label] || item.label;
            });
          }
          for (const key in cartStatsDataCompare) {
            cartStatsDataCompare[key].forEach(item => {
              item.title = labelToTitle[item.label] || item.label;
            });
          }

          setAggregatedData(mergeDicts(cartStatsDataActual, cartStatsDataCompare));
          setComparison({
            qty_orders: getChange(cartStatsDataActual.overall[0].qty_orders, cartStatsDataCompare.overall[0].qty_orders),
            total_value: getChange(cartStatsDataActual.overall[0].total_value, cartStatsDataCompare.overall[0].total_value),
            mean_ticket: getChange(cartStatsDataActual.overall[0].mean_ticket, cartStatsDataCompare.overall[0].mean_ticket),
            activations: getChange(cartStatsDataActual.overall[0].activations, cartStatsDataCompare.overall[0].activations),
            order_mix: getChange(cartStatsDataActual.overall[0].order_mix, cartStatsDataCompare.overall[0].order_mix),
            quantities: getChange(cartStatsDataActual.overall[0].quantities, cartStatsDataCompare.overall[0].quantities),
          });
          setGeneralStats(cartStatsDataActual.overall[0]);
          setLoading(false);
        });
    }
  }, [analysisPeriod]);

  const statsLabels = {
    qty_orders: 'Número de Pedidos',
    total_value: 'Faturamento',
    activations: 'Clientes Positivados',
    mean_ticket: 'Ticket Médio',
    order_mix: 'Mix Médio',
    price: 'Preço Médio',
    quantities: 'Quantidade Média',
  };

  const PAGE_SIZE = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  function LineGraph(props) {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={props.data}
          margin={{
            top: 10, right: 50, left: 0, bottom: 0,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="title" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={displayMetric} name={statsLabels[displayMetric]} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  function BarGraph(props) {
    return (
      <div>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart
              data={props.data.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)}
              layout="vertical"
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
              width={600}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis
                dataKey="title"
                type="category"
                width={100}
                style={{
                  fontSize: '0.8rem',
                }}
                tickFormatter={(value, index) => {
                  const limit = 15; // put your maximum character
                  if (value.length < limit) return value;
                  return `${value.substring(0, limit)}...`;
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey={displayMetric} fill={blue.primary} />
              {/* <Bar dataKey={"compare_" + displayMetric} fill="#8c8c8c" /> */}
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Pagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            total={props.data.length - 1}
            onChange={handleChangePage}
          />
        </div>
      </div>
    );
  }

  function DisplayGraph(props) {
    const sortedData = [...props.data].sort((first, second) => second[displayMetric] - first[displayMetric]);

    const selectOptions = props.data.map((item) => ({
      label: `${item.title} (${item.qty_buyers})`,
      value: item.label,
    }));

    selectOptions.unshift({ label: 'Todos', value: 'all' });

    return (
      graphType === 'values' ? (
        <>
          <Title level={4}>
            Segmentação: {' '}
            <Select
              style={{ width: '300px' }}
              options={selectOptions}
              value={segmentedColumn}
              onChange={(value) => setSegmentedColumn(value)}
            />
          </Title>
          {' '}
          {(segmentedLoading ? <Skeleton active /> : segmentedData && <Table pagination={{ showSizeChanger: true }} tableLayout="auto" scroll={{ x: 'max-content' }} columns={buyerColumns} dataSource={segmentedData} />)}
          {' '}

        </>
      ) : graphType === 'line' ? <LineGraph data={props.data} /> : graphType === 'bar' ? <BarGraph data={sortedData} /> : (
        <Table
          scroll={{
            x: 800,
          }}
          columns={columns}
          dataSource={sortedData}
        />
      ));
  }

  const itemsCustomerTab = [
    {
      label: 'Status do Cliente',
      key: '1',
      column: 'status',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.status} />,
    },
    {
      label: 'Curva ABC',
      key: '2',
      column: 'ABC',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.ABC} />,
    },
    {
      label: 'Data de Cadastro',
      key: '3',
      column: 'registration_category',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.registration_category} />,
    },
    {
      label: 'Quantidade de Pedidos',
      key: '4',
      column: 'num_orders_category',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.num_orders_category} />,
    },
    {
      label: 'Representantes',
      key: '5',
      column: 'selleruser',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.selleruser} />,
    },
    {
      label: 'Estados',
      key: '6',
      column: 'uf',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.uf} />,
    },
    {
      label: 'Cidades',
      key: '7',
      column: 'municipio',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.municipio} />,
    },
    // {
    //   label: 'Tag de Clientes',
    //   key: '8',
    //   column: 'ABC',
    //   disabled: false,
    //   children: 'Em desenvolvimento',
    // },
    {
      label: 'Tipo de Pessoa',
      key: '9',
      column: 'tipo_pessoa',
      disabled: false,
      children: buyerGraphData && <DisplayGraph data={buyerGraphData.tipo_pessoa} />,
    },
  ];

  const items = [
    {
      label: 'Período',
      key: '1',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.week_date} />,
    },
    {
      label: 'Representantes',
      key: '2',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.selleruser_id} />,
    },
    {
      label: 'Estados',
      key: '3',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.uf} />,
    },
    {
      label: 'Cidades',
      key: '4',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.municipio} />,
    },
    {
      label: 'Clientes',
      key: '5',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.buyer_id} />,
    },
    {
      label: 'Produtos',
      key: '6',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.product_id} />,
    },
    // {
    //   label: 'Tag de Clientes',
    //   key: '7',
    //   disabled: false,
    //   children: 'Em desenvolvimento',
    // },
    // {
    //   label: 'Tag de Produtos',
    //   key: '8',
    //   disabled: false,
    //   children: 'Em desenvolvimento',
    // },
    {
      label: 'Métodos de Pagamento',
      key: '9',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.payment_info} />,
    },
    {
      label: 'Métodos de Entrega',
      key: '10',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.shipping_info} />,
    },
    {
      label: 'Emissor do Pedido',
      key: '11',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.who_bought} />,
    },
    {
      label: 'Tipo de Pessoa',
      key: '12',
      disabled: false,
      children: aggregatedData && <DisplayGraph data={aggregatedData.tipo_pessoa} />,
    },
  ];

  const buyerColumns = [
    {
      title: 'Cliente',
      dataIndex: 'buyer_name',
      key: 'buyer_name',
      render: (text) => (
        <>
          <a>{text.split('(')[0].length > 20 ? `${text.split('(')[0].substring(0, 20)}...` : text.split('(')[0]}</a>
          <br />
          {`(${text.split('(')[1]}`}
        </>
      ),
      sorter: (a, b) => a.buyer_name.localeCompare(b.buyer_name),
      fixed: width > 600 ? 'left' : false,
    },
    {
      title: 'Curva ABC',
      dataIndex: 'ABC',
      key: 'ABC',
      sorter: (a, b) => a.ABC.localeCompare(b.ABC),
    },
    {
      title: 'Status do Cliente',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Data de Registro',
      dataIndex: 'registration_category',
      key: 'registration_category',
      sorter: (a, b) => a.registration_category.localeCompare(b.registration_category),
    },
    {
      title: 'Vendedor',
      dataIndex: 'selleruser',
      key: 'selleruser',
      sorter: (a, b) => a.selleruser.localeCompare(b.selleruser),
    },
    {
      title: 'Número de Pedidos',
      dataIndex: 'num_orders_category',
      key: 'num_orders_category',
      sorter: (a, b) => a.num_orders_category.localeCompare(b.num_orders_category),
    },
    {
      title: 'Dias Último Pedido',
      dataIndex: 'days_since_last_order',
      key: 'days_since_last_order',
      sorter: (a, b) => a.days_since_last_order - b.days_since_last_order,
    },
    {
      title: 'Probabilidade',
      dataIndex: 'probability',
      key: 'probability',
      sorter: (a, b) => a.probability - b.probability,
    },
    {
      title: 'Intervalo entre Pedidos',
      dataIndex: 'timedelta',
      key: 'timedelta',
      sorter: (a, b) => a.timedelta - b.timedelta,
    },
    {
      title: 'Ticket Médio',
      dataIndex: 'mean_ticket',
      key: 'mean_ticket',
      sorter: (a, b) => a.mean_ticket - b.mean_ticket,
    },
  ];

  const columns = [
    {
      title: items.filter((item) => item.key === displayTab).label,
      dataIndex: 'label',
      key: 'label',
      render: (text) => <a>{text}</a>,
      ellipsis: true,
    },
    {
      title: 'Número de Pedidos',
      dataIndex: 'qty_orders',
      key: 'qty_orders',
      sorter: true,
      sortOrder: displayMetric === 'qty_orders' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
    {
      title: 'Faturamento',
      dataIndex: 'total_value',
      key: 'total_value',
      sorter: true,
      sortOrder: displayMetric === 'total_value' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
    {
      title: 'Clientes Positivados',
      dataIndex: 'activations',
      key: 'activations',
      sorter: true,
      sortOrder: displayMetric === 'activations' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
    {
      title: 'Ticket Médio',
      dataIndex: 'mean_ticket',
      key: 'mean_ticket',
      sorter: true,
      sortOrder: displayMetric === 'mean_ticket' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
    {
      title: 'Mix Médio',
      dataIndex: 'order_mix',
      key: 'order_mix',
      sorter: true,
      sortOrder: displayMetric === 'order_mix' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
    {
      title: 'Preço Médio',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      sortOrder: displayMetric === 'price' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
    {
      title: 'Quantidade Média',
      dataIndex: 'quantities',
      key: 'quantities',
      sorter: true,
      sortOrder: displayMetric === 'quantities' ? 'descend' : null,
      sortDirections: ['descend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          setDisplayMetric(column.dataIndex);
        },
      }),
    },
  ];

  return (
    <>
      <Tabs
        type="card"
        onChange={(tabValue) => {
          setMainTab(tabValue);
          setDisplayTab('1');
          if (tabValue == '2') {
            setDisplayMetric('qty_buyers');
            setGraphType('bar');
          } else {
            setDisplayMetric('qty_orders');
            setGraphType('line');
          }
        }}
        activeKey={mainTab}
        size="large"
        centered={!(width < 600)}
        tabBarExtraContent={(
          mainTab === '2' && <Badge count={Object.values(filterValues).filter((value) => value !== 'all').length}>
            <Button onClick={() => setFilterDrawerVisible(true)} size={width < 600 ? 'small' : 'middle'} type="dashed" icon={<FilterTwoTone />}>Filtros</Button>
          </Badge>
        )}
      >
        <TabPane tab="Vendas" key="1">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
              justify="space-evenly"
            >
              <Col>
                <Radio.Group size={width < 600 ? 'small' : 'middle'} value={analysisPeriod} onChange={(item) => { item.target.value != 'custom' && setAnalysisPeriod(item.target.value); }}>
                  <Radio.Button value="today">Hoje</Radio.Button>
                  <Radio.Button value="last7">7 dias</Radio.Button>
                  <Radio.Button value="thisMonth">Mês atual</Radio.Button>
                  <Radio.Button onClick={() => { setDrawerVisible(~drawerVisible); setAnalysisPeriod('editCustom'); }} value="custom">Personalizado</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            {loading ? <Skeleton active /> : (
              <>
                <Row gutter={24}>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'qty_orders' ? blue.primary : null }} onClick={() => setDisplayMetric('qty_orders')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.qty_orders}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.qty_orders < 0 ? red.primary : green[7] }}>{isNaN(comparison.qty_orders) ? `+${comparison.qty_orders}` : `${parseFloat(comparison.qty_orders).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={generalStats.qty_orders}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'total_value' ? blue.primary : null }} onClick={() => setDisplayMetric('total_value')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.total_value}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.total_value < 0 ? red.primary : green[7] }}>{isNaN(comparison.total_value) ? `+${comparison.total_value}` : `${parseFloat(comparison.total_value).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={parseFloat(generalStats.total_value).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'activations' ? blue.primary : null }} onClick={() => setDisplayMetric('activations')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.activations}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.activations < 0 ? red.primary : green[7] }}>{isNaN(comparison.activations) ? `+${comparison.activations}` : `${parseFloat(comparison.activations).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={generalStats.activations}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'mean_ticket' ? blue.primary : null }} onClick={() => setDisplayMetric('mean_ticket')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.mean_ticket}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.mean_ticket < 0 ? red.primary : green[7] }}>{isNaN(comparison.mean_ticket) ? `+${comparison.mean_ticket}` : `${parseFloat(comparison.mean_ticket).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={parseFloat(generalStats.mean_ticket).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'order_mix' ? blue.primary : null }} onClick={() => setDisplayMetric('order_mix')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.order_mix}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.order_mix < 0 ? red.primary : green[7] }}>{isNaN(comparison.order_mix) ? `+${comparison.order_mix}` : `${parseFloat(comparison.order_mix).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={generalStats.order_mix}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'price' ? blue.primary : null }} onClick={() => setDisplayMetric('price')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.price}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.order_mix < 0 ? red.primary : green[7] }}>{isNaN(comparison.order_mix) ? `+${comparison.order_mix}` : `${parseFloat(comparison.order_mix).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={generalStats.order_mix}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                  <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'quantities' ? blue.primary : null }} onClick={() => setDisplayMetric('quantities')}>
                      <Statistic
                        title={comparison && (
                          <>
                            {statsLabels.quantities}
                            {' '}
                            <br />
                            {' '}
                            <Text style={{ fontSize: 12, color: comparison.quantities < 0 ? red.primary : green[7] }}>{isNaN(comparison.quantities) ? `+${comparison.quantities}` : `${parseFloat(comparison.quantities).toFixed(2)}%`}</Text>
                          </>
                        )}
                        value={generalStats.quantities}
                        valueStyle={{ color: blue.primary }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Tabs
                  tabPosition="top"
                  defaultActiveKey="1"
                  tabBarExtraContent={{
                    right: <Radio.Group value={graphType} size="small" onChange={(event) => { setGraphType(event.target.value); }}>
                      <Radio.Button disabled={displayTab !== '1'} value="line">Linha</Radio.Button>
                      <Radio.Button value="bar">Barras</Radio.Button>
                      <Radio.Button value="table">Tabela</Radio.Button>
                    </Radio.Group>,
                  }}
                  items={items}
                  onChange={(tabValue) => {
                    if (graphType === 'line' && tabValue !== '1') {
                      setGraphType('bar');
                    }
                    if (tabValue === '1') {
                      setGraphType('line');
                    }
                    setDisplayTab(tabValue);
                  }}
                />
              </>
            )}
          </Space>
          {/* <MapContainer qtyOrders={generalStats.qty_orders} /> */}

        </TabPane>
        <TabPane tab="Clientes" key="2">
          {buyerLoading ? <Skeleton active /> : (
            <>
              <Row gutter={24}>
                <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                  <Card hoverable style={{ margin: 4, borderColor: displayMetric == 'qty_buyers' ? blue.primary : null }} onClick={() => setDisplayMetric('qty_buyers')}>
                    <Statistic
                      title="Quantidade de Clientes"
                      value={buyerGraphData.ABC ? buyerGraphData.ABC.reduce((acc, curr) => acc + curr.qty_buyers, 0) : 0}
                      valueStyle={{ color: blue.primary }}
                    />
                  </Card>
                </Col>
              </Row>
              <Tabs
                tabPosition="top"
                defaultActiveKey="1"
                tabBarExtraContent={{
                  right: <Radio.Group value={graphType} size="small" onChange={(event) => { setGraphType(event.target.value); }}>
                    <Radio.Button value="bar">Barras</Radio.Button>
                    <Radio.Button value="values">Dados</Radio.Button>
                  </Radio.Group>,
                }}
                items={itemsCustomerTab}
                onChange={(tabValue) => {
                  setSegmentedColumn(null);
                  setGraphType('bar');
                  setSegmentedData(null);
                  setDisplayTab(tabValue);
                }}
              />
            </>
          )}
        </TabPane>
      </Tabs>
      <Drawer
        title="Selecionar data"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        <Space direction="vertical" size="middle" rootStyle={{ display: 'flex' }}>
          <Row justify="center">
            <Col>
              <RangePicker
                onChange={(value) => setCustomRange(value.map((e) => e.format('DD/MM/YYYY')))}
                format="DD/MM/YYYY"
              />
            </Col>
          </Row>
          <Button
            block
            type="primary"
            onClick={() => { setDrawerVisible(false); setAnalysisPeriod('custom'); }}
          >
            Salvar
          </Button>
        </Space>

      </Drawer>
      <Drawer
        title="Filtrar Dados"
        placement="right"
        onClose={() => setFilterDrawerVisible(false)}
        open={filterDrawerVisible}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={() => { getBuyerData(); setFilterDrawerVisible(false); }}
          initialValues={filterValues}
        >
          <Divider>Clientes</Divider>
          <Form.Item
            name="customerOrder"
            label="Pedidos na plataforma"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={(value) => setFilterValues({ ...filterValues, customerOrder: value })}
              options={[
                {
                  value: 'all',
                  label: 'Todos',
                },
                {
                  value: 'noOrder',
                  label: 'Sem Pedidos',
                },
                {
                  value: 'hasOrder',
                  label: 'Com Pedidos',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="customerStatus"
            label="Status do cliente"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={(value) => setFilterValues({ ...filterValues, customerStatus: value })}
              options={[
                {
                  value: 'all',
                  label: 'Todos',
                },
                {
                  value: 'served',
                  label: 'Atendidos',
                },
                {
                  value: 'rtb',
                  label: 'Prontos para Comprar',
                },
                {
                  value: 'risk',
                  label: 'Em Risco',
                },
                {
                  value: 'lost',
                  label: 'Perdidos',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="abcCurve"
            label="Curva ABC"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={(value) => setFilterValues({ ...filterValues, abcCurve: value })}
              options={[
                {
                  value: 'all',
                  label: 'Todos',
                },
                {
                  value: 'curveA',
                  label: 'Curva A',
                },
                {
                  value: 'curveB',
                  label: 'Curva B',
                },
                {
                  value: 'curveC',
                  label: 'Curva C',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="registerDate"
            label="Data de cadastro"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={(value) => setFilterValues({ ...filterValues, registerDate: value })}
              options={[
                {
                  value: 'all',
                  label: 'Todos',
                },
                {
                  value: 'today',
                  label: 'Hoje',
                },
                {
                  value: 'last7Days',
                  label: 'Últimos 7 Dias',
                },
                {
                  value: 'last30Days',
                  label: 'Últimos 30 Dias',
                },
                {
                  value: 'last12Months',
                  label: 'Último ano',
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button block type="primary" htmlType="submit">
                    Aplicar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
